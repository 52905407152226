<template>
	<div class="nextbtngroup">
		<button v-if="active>0" @click="btnFn('prev')" >上一页</button>
		<button v-if="active>2 && pagenum.length>5" @click="btnFn(0)">1</button>
		<button v-if="first>1" class="ex">...</button>
		<button v-for="(item) in pagenum.slice(first,last)" :key="item"
						:class="active == item?'active':''"
						@click="btnFn(item)">{{item + 1}}</button>
		<button v-if="pagenum.length>6 && active < pagenum.length - 4" class="ex">...</button>
		<button v-if="pagenum.length>5 && active < pagenum.length - 3" :class="active == pagenum.length - 1?'active':''"
						@click="btnFn(pagenum.length - 1)">{{pagenum.length}}</button>
		<button v-if="pagenum.length > 1 && active < pagenum.length - 2" @click="btnFn('next')" >下一页</button>
	</div>
</template>

<script>
	export default {
		name: "nextbtngroup",
		props:{
			pagetotal:{
				type: Number,
				default: 0
			}
		},
		watch:{
			active(val){
				return val
			},
			pagetotal(val){
				return val
			},
		},
		data(){
			return {
				pagenum:  Array.from(Array(this.pagetotal), (v,k) =>k),
				pagesize: 10,
				first: 0,
				last: 5,
				active:0
			}
		},
		created(){
		},
		methods:{
			btnFn(index){
				let _this = this;
				if (index == 'prev'){
					index = _this.active - 1;
				}else if (index == 'next'){
					index = _this.active + 1;
				}
				_this.active = index;
				if (_this.pagenum.length > 5){
					if (index >= 2 && index <= _this.pagenum.length - 3){
						_this.first = index - 2;
						_this.last = index + 3;
					}else if(index > _this.pagenum.length - 3){
						_this.first = _this.pagenum.length - 5;
						_this.last = _this.pagenum.length;
					}else if(index < 2){
						_this.first = 0;
						_this.last = 5;
					}
				}
				_this.goPage(_this.active*10);
			},
			goPage(index){
				this.$parent.goListPage(index);
			}
		}
	}
</script>

<style lang="scss" scoped>
.nextbtngroup{
	width: 100%;
	height: 30px;
	button{
		min-width: 30px;
		height: 30px;
		padding: 0 15px;
		border-radius: 2px;
		border: 1px solid #EEEEEE;
		margin-right: 15px;
		color: #333333;
		&.ex{
			border: 0px solid #EEEEEE;
		}
		&:last-child{
			margin-right: 0;
		}
		&.active{
			background: #FD8A25;
			border: 1px solid #FD8A25;
			color: #FFFFFF;

		}
	}
}
</style>
