<template>
	<div class="index">
		<ul class="slideBox" ref="slidBox" v-if="slideData.length>0">
			<li :style="getBg(slideData[slideData.length-1].url)" alt=""
					@click="imgClick(slideData[slideData.length-1].key)"></li>
			<li  v-for="(item,index) in slideData" :key="index" :style="getBg(item.url)" alt=""
						@click="imgClick(item.key)"
						@touchstart='touchstart' @touchmove='touchmove' @touchend = 'touchend'></li>
			<li  :style="getBg(slideData[0].url)" alt=""
						@click="imgClick(slideData[0].key)"></li>
		</ul>
		<button v-if="$root.$device == 'pc'" class="prev" @click="prevFn"></button>
		<button v-if="$root.$device == 'pc'" class="next" @click="nextFn(true)"></button>
		<div class="spotBox">
			<span v-for="(item,index) in slideData" :key="index"
						:class="index == active?'active':''"></span>
		</div>
	</div>
</template>

<script>
	export default {
		name: "index",
		props:{
			slideData:{
				type: Array,
				default: ()=>{
					return [{
						url:'',
						key:''
					}]
				}
			}
		},
		watch:{
			slideData(val){
				return val
			}
		},
		data(){
			return{
				active: 0,
				interval: null, // 自动播放
				timeout: null,  // 无操作10s后,重新自动播放
				isBlock: false, // 连点锁,
				startX: 0,
				startY:0,
				moveX:0,
				moveY:0,
				translateX: 0,
			}
		},
		mounted() {
			let _this = this;
			_this.$nextTick(function(){
				setTimeout(function(){
					_this.autoPlayFn();
					_this.translateX = _this.$refs.slidBox.childNodes[0].offsetWidth;
				},300)
			})
		},
		methods:{
			getBg(url){
				return 'background-image:url("'+url+'")'
			},
			imgClick(key){
				let url = window.location.href.split('#')[0];
				window.open(url+'live?videokey='+key, '_blank');
			},
			touchstart(e){
				this.clearIntervalFn()
				this.moveX = 0;
				this.moveY = 0;
				this.startX = e.touches[0].clientX;
				this.startY = e.touches[0].clientY;
			},
			touchmove(e){
				this.moveX = this.startX - e.touches[0].clientX;
				this.moveY = this.startY - e.touches[0].clientY;
				let dis = parseInt(this.translateX)+parseInt(this.moveX);
				this.$refs.slidBox.style.transition = "all 0s ease-in-out";
				this.$refs.slidBox.style.transform = "translateX(-"+dis+"px)";
			},
			touchend(){
				if (this.moveX <= -80) { // 右滑 上一张
					this.prevFn();
				}else if(this.moveX >= 80){  // 左滑 下一张
					this.nextFn();
				}else{
					this.$refs.slidBox.style.transition = "all .3s ease-in-out";
					this.$refs.slidBox.style.transform = "translateX(-"+this.translateX+"px)";
				}
			},
			autoPlayFn(){
				this.clearIntervalFn()
				let _this = this;
				_this.interval = setInterval(function(){
					_this.active = _this.active + 1
					_this.slideBoxMove(_this.active)
				},3000)
			},
			clearIntervalFn(){
				let _this = this;
				if (this.interval)clearInterval(this.interval);
				if (this.timeout)clearTimeout(this.timeout);
				this.timeout = setTimeout(function(){
					_this.autoPlayFn()
				},10000)
			},
			prevFn(){
				if (this.isBlock){
					return false;
				}
				this.isBlock = true;
				this.clearIntervalFn()
				this.active = this.active - 1;
				this.slideBoxMove(this.active);
			},
			nextFn(){
				if (this.isBlock){
					return false;
				}
				this.isBlock = true;
				this.clearIntervalFn()
				this.active = this.active + 1;
				this.slideBoxMove(this.active)
			},
			slideBoxMove(index){
				let slideW = this.$refs.slidBox.childNodes[0].offsetWidth;
				this.translateX = slideW * (index+1);
				this.$refs.slidBox.style.transition = "all .3s ease-in-out";
				this.$refs.slidBox.style.transform = "translateX(-"+this.translateX+"px)";
				let _this = this;
				setTimeout(function(){
					_this.isBlock = false;
				},300);
				let length = this.slideData.length-1;
				if (this.active > length){
					this.active = 0;
					this.slideBoxInit()
				}else if (this.active < 0){
					this.active = length;
					this.slideBoxInit()
				}
			},
			slideBoxInit(){
				let _this = this;
				let slideW = this.$refs.slidBox.childNodes[0].offsetWidth;
				_this.translateX = slideW * (this.active+1);
				setTimeout(function(){
					_this.$refs.slidBox.style.transition = "all 0s ease-in-out";
					_this.$refs.slidBox.style.transform = "translateX(-"+_this.translateX+"px)";
				},300)
			},
		},
		destroyed() {
			this.clearIntervalFn()
		}
	}
</script>

<style lang="scss" scoped>
	.index{
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		padding-bottom: 0 !important;

		&:hover{
			.prev{
				display: block;
			}
			.next{
				display: block;
			}
		}
		.slideBox{
			height: 100%;
			display: inline-flex;
			transform: translateX(-750px);
			transition: all .3s ease-in-out;
			@media screen and(max-width: 1139px){
				transform: translateX(-100vw);
			}
			li{
				overflow: hidden;
				width: 750px;
				height: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				@media screen and(max-width: 1139px){
					width: 100vw;
					min-width: 100vw;
					max-width: 100vw;
				}
			}
		}
		button{
			width: 32px;
			height: 48px;
			position: absolute;
			top: 50%;
			margin-top: -24px;
			background-size: 15px 22px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: rgba(0,0,0,0.8);
		}
		.prev{
			display: none;
			left: 0;
			background-image: url("./icon/prev.png");
			@media screen and(max-width: 1139px){
				display: none;
			}
		}
		.next{
			display: none;
			right: 0;
			background-image: url("./icon/next.png");
			@media screen and(max-width: 1139px){
				display: none;
			}
		}
		.spotBox{
			width: 100%;
			height: 2px;
			position: absolute;
			bottom: 20px;
			left: 0;
			z-index: 22;
			text-align: center;
			@media screen and(max-width: 1139px){
				height: 0.4rem;
				bottom: 2rem;
			}
			span{
				display: inline-block;
				width: 20px;
				height: 2px;
				background-color: white;
				margin-left: 5px;
				@media screen and(max-width: 1139px){
					width: 0.4rem;
					height: 0.4rem;
					margin-left: 0.5rem;
				}
				&:first-child{
					margin:0
				}
				&.active{
					background-color: #FD8A25;
					@media screen and(max-width: 1139px){
						width: 0.8rem;
					}
				}
			}
		}
	}
</style>
