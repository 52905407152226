<template>
	<div class="index" ref="index">
		<div class="slideRow" v-if="slideData.length>0">
			<comSlide :slideData="slideData" />
		</div>
		<section class="listbox">
			<h2>直播</h2>
			<ul class="list ">
				<figure class="clearfix" v-for="(item,index) in listData" :key="index" @click="goPage(item.key)">
<!--					<img :src="item.video_image.original?item.video_image.original[0].url:''" alt="">-->
					<li :style="getBg(item.video_image.original)"></li>
					<div class="status-box">
						<span :class="'status'+getStatus(item.live_status)" >{{statusText[getStatus(item.live_status)]}}</span>
						<em v-if="getStatus(item.live_status) == 2">
							<indexCountDown :startTime="item.time_start" />
						</em>
					</div>
					<figcaption :ref="index+'lirow'">
						<h3>{{item.title}}</h3>
						<div class="portraitRow">
							<span  v-if="getPortrait(item.company_image)!==''" class="portrait" :style="{backgroundImage: 'url(' + getPortrait(item.company_image) + ')'}"></span>
							<em class="por-label">{{item.company_name}}</em>
						</div>
						<p class="describe">{{item.company_summary}}</p>
						<div class="timerow">
							<span class="time-span">{{getDate(item.time_start,item.time_end)}}</span>
							<span class="likes" ref="handclick" @click.stop="upvotesFn(item.guid,index)">
								<i class="icon hand"></i>
								<em class="num">{{initmoney(item.number_of_upvotes)}}</em>
							</span>
							<span class="views">
								<i class="icon eyes"></i>
								<em class="num">{{initmoney(item.number_of_reads)}}</em>
							</span>
						</div>
					</figcaption>
				</figure>
			</ul>
			<div class="loading">
				{{loading}}
			</div>
		</section>
		<div class="btnGroup" v-if="total>1">
			<nextbtngroup  :pagetotal="total"/>
		</div>
	</div>
</template>
<script>
	import comSlide from "@/components/slidebox/index"
	import nextbtngroup from "@/components/nextbtngroup/index"
	import indexCountDown from "@/components/indexcountdown";
	import {debounce, str2time, initmoney} from "../utils";

	export default {
		name: "index",
		components:{
			comSlide,nextbtngroup,indexCountDown
		},
		watch:{
			listData:function(){
				let  _this = this
				this.$nextTick(function(){
					setTimeout(function(){
						let arr = _this.$refs;
						for (let i in arr){
							let item = arr[i][0];
							if (item && item.childNodes){
								if(item.childNodes[0].offsetHeight > 25 ){
									item.childNodes[2].style.WebkitLineClamp = 2
								}else{
									item.childNodes[2].style.WebkitLineClamp = 3
								}
							}
						}
					},1000)
					let thisheight = document.documentElement.scrollHeight||document.body.scrollHeight;

					top.postMessage('pageHeight,'+thisheight, '*');
				});
			}
		},
		data(){
			return {
				total: 0,
				timer:null,
				loading:'加载中...',
				dataSource:[],
				statusText:['直播预告','直播中','即将开始','回放生成中','观看回放','已结束'],
				slideData:[],
				listData:[],
				pageNum: 0,
				upvotesBlock: []
			}
		},
		created() {
			let _this = this;
			this.getLiveList(_this.pageNum);
			this.getLiveSlide();
			window.addEventListener('message', function (e) {
				var _height = e.data;
				if (_height ==  'showMore'){
					if (_this.$root.$device  == 'h5'){
						_this.getScroll();
					}
				}
			}, false);
		},
		mounted() {
		},
		methods:{
			initmoney(val){
				return initmoney(val)
			},
			getBg(url){
				let str = '';
				if (url){
					str = 'background-image:url("'+url[0].url+'")'
				}
				return str;
			},
			addUpvotes(guid,index){
				let  _this = this;
				_this.$api.addUpvotes({
					guid:guid,
					data:{
						type: 'add'
					},
				}).then((ok)=>{
					if (ok.result == 'ok'){
						let str = require('@/assets/images/icons/hand_l.png');
						_this.$refs.handclick[index].childNodes[0].style.backgroundImage = 'url("'+str+'")';
						_this.$refs.handclick[index].childNodes[1].style.color = 'rgb(234, 113, 20)'

						_this.upvotesBlock[index] = true;
						_this.listData[index].number_of_upvotes += 1;
					}
				});
			},
			delUpvotes(guid,index){
				let  _this = this;
				_this.$api.delUpvotes({
					guid:guid,
					data:{
						type: 'add'
					},
				}).then((ok)=>{
					if (ok.result == 'ok'){
						let str = require('@/assets/images/icons/hand_w.png');
						_this.$refs.handclick[index].childNodes[0].style.backgroundImage = 'url("'+str+'")';
						_this.$refs.handclick[index].childNodes[1].style.color = '#999999'

						_this.upvotesBlock[index] = false;
						_this.listData[index].number_of_upvotes -= 1;
					}
				});
			},
			upvotesFn(guid,index){
				let _this = this;
				if(_this.upvotesBlock[index]){
					_this.delUpvotes(guid,index)
				}else{
					_this.addUpvotes(guid,index)
				}
			},
			getPortrait(obj){
				if ( obj.original){
					return obj.original[0].url;
				}else{
					return ''
				}
			},
			getDate(str1,str2){
				let splitStr = '/';
				if (this.$root.$device == 'pc'){
					splitStr = '/';
				}else{
					splitStr = '.';
				}
				str1 = str2time(str1,splitStr);
				str2 = str2time(str2,splitStr);
				return str1.slice(0,16)+'-'+str2.slice(11,16)
			},
			getStatus(status){
				let num = 0;
				switch (status) {
					case 'live_teaser':
						num = 0
						break;
					case 'living':
						num = 1
						break;
					case 'coming_soon':
						num = 2
						break;
					case 'generate_replay':
						num = 3
						break;
					case 'live_replay':
						num = 4
						break;
				}
				return num;
			},
			// 获取轮播图数据?is_top=1
			getLiveSlide(){
				let  _this = this;
				_this.$api.liveStreamList({
					url:'?is_top=1',
					data:{},
				}).then((ok)=>{
					_this.slideData = ok.data.map(function(item){
						return {
							url: item.video_image.original[0].url,
							key: item.key
						}
					});
				});
			},
			// 请求列表
			getLiveList(number){
				let  _this = this;
				_this.$api.liveStreamList({
					url:'?fields=live_status;number_of_reads;number_of_upvotes&orderby=time_start&sort=desc&limit=10&offset='+number,
					data:{},
				}).then((ok)=>{
					_this.listData = _this.listData.concat(ok.data);
					_this.total = Math.ceil(parseInt(ok.cursor.total)/10);
				});
			},
			goListPage(number){
				let  _this = this;
				_this.$api.liveStreamList({
					url:'?fields=live_status;number_of_reads;number_of_upvotes&sort=asc&limit=10&offset='+number,
					data:{
					},
				}).then((ok)=>{
					_this.listData = ok.data;
					_this.total = Math.ceil(parseInt(ok.cursor.total)/10);
				});
			},
			goPage(key){
				let url = window.location.href.split('#')[0]+'live?videokey='+key;
				window.open(url, '_blank');
			},
			getIcon(name){
				let str = require('@/assets/images/icons/'+name+'.png');
				return 'background-image:url("'+str+'")';
			},
			getScroll: debounce(function(){
				let _this  = this;
				if (_this.listData.length < _this.total){
					_this.pageNum ++ ;
					_this.getLiveList(_this.pageNum*10);
				}else{
					_this.loading = '到底了'
				}
			},500),
			scrollDir(){
				let dir = '';
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
					document.body.scrollTop;
				let scroll = scrollTop - this.i;
				this.i = scrollTop;
				if(scroll<0){
					dir = 'up';
				}else{
					dir = 'down'
				}
				return dir;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.index{
		width: 750px;
		margin: 0 auto;
		padding-bottom: 40px;
		@media screen and(max-width: 1139px){
			width: 100vw;
			padding-bottom: 0;
		}
		.slideRow{
			width: 100%;
			height: 414px;
			margin: 40px auto;
			@media screen and(max-width: 1139px){
				height: 20.7rem;
				margin: 0;
			}
		}
		.listbox{
			width: 100%;
			h2{
				width: 100%;
				padding-bottom: 15px;
				border-bottom: 3px solid #393E46;
				@include fontS(PingFang SC,normal,500,24px,33px);
				color: #333333;
				@media screen and(max-width: 1139px){
					display: none;
				}
			}
			.list{
				width: 100%;
				@media screen and(max-width: 1139px){
					padding: 0.5rem 1.5rem 0;
				}
				figure{
					cursor: default;
					padding: 15px 0;
					width: 100%;
					border-top: 1px solid #EEE;
					position: relative;
					&:first-child{
						border-top: 0 solid #EEE;
					}
					@media screen and(max-width: 1139px){
						padding: 1.5rem 0;
					}
					li{
						float: left;
						width: 295px;
						height: 163px;
						margin-right: 20px;
						background-size: cover;
						background-position: center center;
						@media screen and(max-width: 1139px){
							width: 14.25rem;
							height: 9.5rem;
							margin-right: 1rem;
							border-radius: 0.02rem;
						}
					}
					.status-box{
						width: 295px;
						position: absolute;
						left: 0;
						top: 15px;
						@media screen and(max-width: 1139px){
							width: 14.25rem;
							top: 1.5rem;
						}
						span{
							display: inline-block;
							height: 100%;
							padding: 5px 10px;
							border-radius: 2px;
							@include fontS($size:12px,$lineH:17px,$weight:300);
							color: #FFFFFF;
							margin-right: 10px;
							@media screen and(max-width: 1139px){
								margin-right: 0.5rem;
								padding: 0.4rem 0.5rem;
								border-radius: 0.2rem;
								@include fontS($size:1.1rem,$lineH:1.6rem,$weight:300);
							}
							&.status0{
								background: #1C81FF;
							}
							&.status1{
								padding-left: 23px;
								background-image: url("../assets/images/icons/live.png");
								background-size: 10px 12px;
								background-position:8px center;
								background-repeat: no-repeat;
								background-color: #FF2A2A;
								@media screen and(max-width: 1139px){
									padding-left: 1.8rem;
									background-size: 1rem 1rem;
									background-position:0.5rem center;
								}
							}
							&.status2{
								background: #FD8A25;
							}
							&.status3{
								background: rgba(0,0,0,0.66);
							}
							&.status4{
								background: rgba(0,0,0,0.67);
							}
							&.status5{
								background: rgba(0,0,0,0.67);
							}
						}
						em{
							display: inline-flex;
							height: 27px;
							align-items: center;
							@media screen and(max-width: 1139px){
								height: 2.4rem;
							}
						}
					}
					figcaption{
						float: left;
						width: calc(100% - 319px);
						height: 163px;
						overflow: hidden;
						position: relative;
						@media screen and(max-width: 1139px){
							width: calc(100% - 15.25rem);
							height: 9.6rem;
						}
						h3{
							@include fontS($weight:500,$size:18px,$lineH:25px);
							color: #333333;
							@include text-ellipsis();
							margin-bottom: 10px;
							@media screen and(max-width: 1139px){
								@include fontS($weight:500,$size:1.6rem,$lineH:2.5rem);
								@include text-ellipsis();
								margin-bottom: 0;
							}
						}
						.portraitRow{
							@media screen and(max-width: 1139px){
								display: none;
							}
							display: flex;
							width: 100%;
							padding-bottom: 12px;
							align-items: center;
							justify-items: start;
							.portrait{
								display: inline-block;
								width: 24px;
								height: 24px;
								background-size: 100% 100%;
								border-radius: 50%;
								margin-right: 6px;
							}
							em{
								@include fontS($weight:400,$size:14px,$lineH:24px);
								color: #333333;
							}
						}
						.describe{
							@media screen and(max-width: 1139px){
								display: none;
							}
							display: inline-block;
							width: 100%;
							@include fontS($weight:400,$size:12px,$lineH:17px);
							@include text-ellipsis(2);
							color: #666666;
						}
						.timerow{
							position: absolute;
							width: 100%;
							left: 0;
							bottom: 0;

							span{
								float: right;
								margin-left: 20px;
								@include fontS($weight:400,$size:14px,$lineH:22px);
								color: #999999;
								display: flex;
								align-items: center;
								&.likes{
									cursor: pointer;
								}
								@media screen and(max-width: 1139px){
									&.likes{
										display: none;
									}
									&.views{
										display: none;
									}
								}
								.icon{
									display: inline-block;
									width: 22px;
									height: 22px;
									background-size: 20px 16px;
									background-repeat: no-repeat;
									background-position: center center;
									margin-right: 5px;
									&.eyes{
										background-size: 22px 16px;
										background-image: url("../assets/images/icons/eyes_w.png");
									}
									&.hand{
										background-size: 18px 17px;
										background-image: url("../assets/images/icons/hand_w.png");
									}
								}
								.num{
									@include fontS($weight:400,$size:14px,$lineH:22px);
								}

							}
							.time-span{
								float: left;
								color: #1C81FF;
								margin-left: 0;
								@media screen and(max-width: 1139px){
									@include fontS($weight:400,$size:1.2rem,$lineH:2.5rem);
								}
							}
						}
					}
				}
			}
			.loading{
				display: none;
				@media screen and(max-width: 1139px){
					display: block;
					width: 100%;
					text-align: center;
					padding: 1rem 0;
					@include fontS($size:1.2rem,$lineH:1.6rem);
					color: #999999;
				}
			}
		}
		.btnGroup{
			width: 100%;
			padding: 54px 0 54px;
			text-align: center;
		}
	}
</style>
